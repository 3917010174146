<template>
	<div>
		<div
			style="position: relative"
			class="d-flex align-center pb-2">
			<!-- user name -->
			<h1>
				{{
					student.name ||
					student.Account.FirstName + ' ' + student.Account.LastName
				}}
			</h1>
			<v-spacer></v-spacer>

			<!-- mark as read button -->
			<v-btn
				v-if="unReadMessages.length"
				:disabled="loadingMarksAsRead"
				:loading="loadingMarksAsRead"
				@click="markAsRead"
				color="primary">
				<span> تحدد كمقروء </span>
			</v-btn>

			<!-- search button -->
			<v-btn
				color="primary"
				icon
				@click="
					showSearch = !showSearch;
					searchText = null;
				">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<v-icon
							v-bind="attrs"
							v-on="on">
							mdi-magnify
						</v-icon>
					</template>
					<span>بحث ctrl+f</span>
				</v-tooltip>
			</v-btn>

			<!-- search input -->
			<v-expand-transition>
				<div
					transition="scroll-x-transition"
					v-show="showSearch"
					class="pa-1 pb-0 white"
					style="
						position: absolute;
						top: 100%;
						right: 0;
						left: 0;
						z-index: 2;
					">
					<v-text-field
						@keydown.esc="showSearch = false"
						autofocus
						v-model="searchText"
						label="بحث"
						class="mb-2"
						dense
						outlined
						hide-details=""></v-text-field>
					<v-divider></v-divider>
				</div>
			</v-expand-transition>
		</div>
		<v-divider></v-divider>

		<!-- messages -->
		<div class="messages d-flex flex-column-reverse align-center">
			<div
				class="d-flex align-end"
				style="width: 100%">
				<div
					class="d-flex flex-column"
					style="width: 100%">
					<!-- loader -->
					<template v-if="loading">
						<div class="d-flex justify-center align-center py-16">
							<v-progress-circular
								indeterminate
								color="primary"></v-progress-circular>
						</div>
					</template>

					<!-- messages -->
					<template v-else>
						<div class="d-flex flex-column">
							<!-- fetched messages -->
							<div
								v-for="(message, i) in messages.filter((e) => {
									return searchText
										? e.Content.includes(searchText)
										: true;
								})"
								:key="i + 'old'"
								class="px-0 my-3">
								<massage
									:message="message"
									:sender="
										student.name ||
										student.Account.FirstName
									"></massage>
							</div>

							<!-- new messages -->
							<div
								v-for="(message, i) in newMessages.filter(
									(e) => {
										return searchText
											? e.Content.includes(searchText)
											: true;
									},
								)"
								:key="i + 'new' + message.isFail"
								:class="i + 'new' + message.isFail"
								class="px-0 my-3">
								<massage
									@re-send="reSend(i)"
									:message="message"
									:sender="
										student.name ||
										student.Account.FirstName
									"></massage>
							</div>
						</div>
						<!-- there is no messages -->
						<div
							v-if="!messages.length && !newMessages.length"
							class="text-h5 text--secondary text-center"
							style="margin-bottom: 30vh">
							<span v-if="searchText">لا يوجد رسالة مطابقة</span>
							<span v-else> ما من رسائل بعد.. </span>
						</div>
					</template>

					<!-- form -->
					<div class="messages-input white px-1">
						<div class="d-flex pt-2">
							<!-- textarea -->
							<div class="ml-2 massage-input">
								<v-textarea
									autofocus
									v-model="messageText"
									label="الرسالة"
									outlined
									dense
									hide-details
									:rows="3"
									no-resize
									auto-grow
									class="rounded-lg"></v-textarea>
							</div>

							<!-- send button -->
							<div>
								<v-btn
									:disabled="!messageText"
									@click="sendMessage"
									class="py-6"
									color="primary">
									<span v-if="!$vuetify.breakpoint.mobile">
										إرسال
									</span>
									<v-icon
										right
										class="send-icon">
										mdi-send-variant
									</v-icon>
								</v-btn>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Massage from '@/components/Massage.vue';
import { mapState } from 'vuex';

export default {
	name: 'Chat',

	components: { Massage },

	props: {
		student: {
			typeof: Object,
		},
		courseName: {
			typeof: String,
		},
		loading: {
			typeof: Boolean,
			default: false,
		},
	},

	data() {
		return {
			loadingMarksAsRead: false,
			messageText: '',

			newMessages: [],

			showSearch: false,
			searchText: '',
		};
	},

	watch: {
		loadingComp() {
			if (this.loadingComp === true) {
				this.newMessages = [];
				this.messageText = '';
			}
		},
	},

	computed: {
		loadingComp: {
			get() {
				return this.loading;
			},
			set(newVal) {
				this.$emit('update:loading', newVal);
			},
		},
		unReadMessages() {
			return this.messages
				.filter((e) => !e.IsOpened && !e.IsFromAdmin)
				.map((e) => e.Id);
		},
		...mapState({
			messages: (state) => state.message.messages,
		}),
	},

	methods: {
		markAsRead() {
			this.loadingMarksAsRead = true;
			this.$store
				.dispatch('message/markAsRead', {
					ids: this.unReadMessages,
				})
				.then(() => {
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						'تم التحديد كل الرسائل كمقروء',
					);
					this.$emit('refresh');
				})
				.catch(() => {})
				.finally(() => {
					this.loadingMarksAsRead = false;
				});
		},
		comperedMessages() {
			const arr = this.messages;
			this.newMessages.forEach((e, i) => {
				if (!!arr.find((c) => c.Id === e.Id)) {
					this.newMessages.splice(i, 1);
				}
			});
		},
		reSend(i) {
			const message = this.newMessages[i];
			message.isFail = false;
			this.newMessages.splice(i, 1);
			this.newMessages = [...this.newMessages, message];
			const messageIndex = this.newMessages.length - 1;

			this.$store
				.dispatch('message/sendMessage', {
					Content: this.newMessages[messageIndex].Content,
					StudentId: this.student.Id,
					StudentUserName: this.student.UserName,
					CourseName: this.courseName,
				})
				.then((res) => {
					this.newMessages[messageIndex] = res.data;
					this.fetchMessages();
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						'تم إرسال الرسالة',
					);
					this.messageText = '';
				})
				.catch(() => {
					this.newMessages[messageIndex].isFail = true;
					this.$eventBus.$emit(
						'show-snackbar',
						true,
						'حدث خطأ ما, لم يتم الإرسال',
					);
				});
		},
		sendMessage() {
			this.newMessages = [
				...this.newMessages,
				{
					Content: this.messageText,
					IsFromAdmin: true,
					group: false,
					IsParent: false,
					SentAt: new Date(),
					isFail: false,
				},
			];
			const messageIndex = this.newMessages.length - 1;
			this.$store
				.dispatch('message/sendMessage', {
					Content: this.messageText,
					StudentId: this.student.Id,
					StudentUserName: this.student.UserName,
					CourseName: this.courseName,
				})
				.then((res) => {
					this.newMessages[messageIndex] = res.data;
					this.fetchMessages();
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						'تم إرسال الرسالة',
					);
					this.messageText = '';
				})
				.catch(() => {
					// const x = [...this.newMessages];
					this.newMessages[messageIndex].isFail = true;
					// this.newMessages = x;
					this.$eventBus.$emit(
						'show-snackbar',
						true,
						'حدث خطأ ما, لم يتم الإرسال',
					);
				});
		},
		fetchMessages() {
			this.$store
				.dispatch('message/fetchMessages', {
					studentId: this.student.Id,
				})
				.then(() => {
					this.comperedMessages();
				})
				.catch(() => {});
		},
	},
	mounted() {
		window.addEventListener('keydown', (e) => {
			if (e.ctrlKey && e.code === 'KeyF') {
				this.showSearch = true;
				e.preventDefault();
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.messages-input {
	position: sticky;
	bottom: 0;
}
.messages {
	height: calc(100vh - 200px);
	max-height: calc(100vh - 200px);
	overflow-y: scroll;
	scroll-behavior: smooth;
}
.massage-input {
	flex: 1;
}
.send-icon {
	transform: scale(-1);
}
</style>
