import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        specialties: [],
        students: [],
        oldMessages: [],
        messages: [],
        brodcastMessages: [],
    },

    actions: {
        fetchOldMessages({ rootState, commit }, { unReadOnly, pageNumber }) {
            return window.axios
                .get(
                    `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/all-messages`,
                    {
                        params: {
                            unReadOnly,
                            pagingParameter: { pageNumber, pageSize: 200 },
                        },
                    },
                )
                .then((res) => {
                    commit(types.OLD_MESSAGES, res.data);
                });
        },
        fetchAllBrodcast({ rootState, commit }) {
            return window.axios
                .get(
                    `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/get-group-messages`,
                )
                .then((res) => {
                    commit(types.BRODCAST_MESSAGES, res.data);
                });
        },
        fetchSpecialties({ rootState, commit }) {
            return window.axios
                .get(
                    `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/get-specialties`,
                )
                .then((res) => {
                    commit(types.SPECIALTIES, res.data);
                });
        },
        fetchStudents({ rootState, commit }, { courseId }) {
            return window.axios
                .get(
                    `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/course-students`,
                    { params: { courseId: courseId } },
                )
                .then((res) => {
                    commit(types.STUDENTS, res.data);
                });
        },
        fetchMessages({ rootState, commit }, { studentId }) {
            return window.axios
                .get(
                    `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/student-messages`,
                    { params: { studentId: studentId } },
                )
                .then((res) => {
                    commit(types.MESSAGES, res.data);
                });
        },
        sendMessage(
            { rootState },
            { Content, StudentId, StudentuserName, CourseName },
        ) {
            return window.axios.post(
                `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/send-message`,
                {
                    Content,
                    StudentId,
                    StudentuserName,
                    CourseName,
                    centerId: rootState.login.activeUser.CenterId,
                },
            );
        },
        sendBrodcasetMessage(
            { rootState },
            { Content, SpecialtyId, CourseId, AllSchool },
        ) {
            return window.axios.post(
                `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/send-group-message`,
                {
                    Content,
                    SpecialtyId,
                    CourseId,
                    AllSchool,
                },
            );
        },
        markAsRead({ rootState }, { ids }) {
            return window.axios.put(
                `/center/${rootState.login.activeUser.CenterId}/user/${rootState.login.activeUser.UserName}/change-message-status`,
                null,
                {
                    params: {
                        ids,
                    },
                },
            );
        },
        clear({ commit }) {
            commit('clear');
        },
    },

    mutations: {
        [types.SPECIALTIES](state, specialties) {
            state.specialties = specialties;
        },
        [types.STUDENTS](state, students) {
            state.students = students;
        },
        [types.MESSAGES](state, messages) {
            state.messages = messages;
        },
        [types.OLD_MESSAGES](state, oldMessages) {
            state.oldMessages = oldMessages;
        },
        [types.BRODCAST_MESSAGES](state, brodcastMessages) {
            state.brodcastMessages = brodcastMessages;
        },
        clear(state) {
            state.specialties = [];
            state.students = [];
            state.oldMessages = [];
            state.messages = [];
            state.brodcastMessages = [];
        },
    },
};
