<template>
	<v-sheet
		class="d-flex white message"
		:class="{
			sent: !message.IsFromAdmin,
			group: message.IsGroupMessage,
		}">
		<v-avatar
			class="rounded-circle mx-2"
			color="white--text"
			:class="{ primary: !message.IsFromAdmin }"
			:size="$vuetify.breakpoint.mobile ? 30 : 50">
			<img
				src="../assets/EdunixLogo.png"
				alt=""
				v-if="message.IsFromAdmin" />
			<span v-else>{{ sender[0] }}</span>
		</v-avatar>

		<v-card
			elevation="2"
			class="massage-text pa-3 pb-1"
			:class="{ fail: message.isFail }"
			rounded="lg">
			<!-- tips -->

			<v-tooltip top>
				<template v-slot:activator="{ on, attrs }">
					<v-icon
						v-if="message.IsGroupMessage"
						small
						class="mt-n2"
						color="myOrange"
						v-bind="attrs"
						v-on="on">
						mdi-multicast
					</v-icon>
					<v-icon
						v-if="message.IsParent"
						small
						class="mt-n2"
						color="myOrange"
						v-bind="attrs"
						v-on="on">
						mdi-account-supervisor
					</v-icon>
				</template>
				<span v-if="message.IsGroupMessage">رسالة جماعية</span>
				<span v-if="message.IsParent">من ولي الامر</span>
			</v-tooltip>

			<!-- content -->
			<div
				ref="message"
				:class="{ 'font-weight-bold': message.IsGroupMessage }"
				v-html="finMessage"></div>
			<v-divider class="mt-3 mb-1"></v-divider>

			<!-- time -->
			<div class="d-flex align-center text-caption text--secondary">
				<span>{{ dateFormater(new Date(message.SentAt)) }}</span>
				<template v-if="!message.IsFromAdmin">
					<template v-if="message.IsOpened">
						<v-icon
							class="ms-2 me-1"
							small>
							mdi-check-all
						</v-icon>
						مقروءة
					</template>
				</template>
				<template v-else>
					<template v-if="message.isFail">
						<v-icon
							class="ms-2 me-1"
							small>
							mdi-alert-outline
						</v-icon>
						فشل الإرسال
					</template>
					<template v-else-if="!message.Id">
						<v-icon
							class="ms-2 me-1"
							small>
							mdi-clock-time-twelve-outline
						</v-icon>
						يتم الإرسال
					</template>
					<template v-else-if="message.Id">
						<v-icon
							class="ms-2 me-1"
							small>
							mdi-check
						</v-icon>
						تم الإرسال
					</template>
				</template>
			</div>
		</v-card>
		<v-menu offset-y>
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					icon
					v-bind="attrs"
					v-on="on">
					<v-icon>mdi-dots-horizontal</v-icon>
				</v-btn>
			</template>
			<v-list>
				<v-list-item
					v-if="message.isFail"
					@click="$emit('re-send')">
					إعادة المحاولة
				</v-list-item>
				<v-list-item @click="copy"> نسخ </v-list-item>
			</v-list>
		</v-menu>
	</v-sheet>
</template>

<script>
import { dateFormater } from '@/helpers/helpers';
export default {
	name: 'Massage',

	props: ['message', 'sender'],

	data() {
		return {
			reg: /((http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/\S*)?)/gim,
		};
	},

	computed: {
		finMessage() {
			let message = this.message.Content?.replace(this.reg, (a, b) => {
				let link = b;
				/^(www\.)/.test(b) && (link = 'https://' + b);

				!/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)/.test(
					link,
				) && (link = 'https://www.' + b);

				return `<a href='${link}' target='_blank'>${b}</a>`;
			}).replaceAll('\n', '<br/>');
			return message;
		},
	},

	methods: {
		dateFormater,
		copy() {
			navigator.clipboard.writeText(this.message.Content);
		},
	},
};
</script>

<style lang="scss" scoped>
.fail {
	border: 1px solid #f17e7e;
	background-color: #e8cece;
}
.sent {
	flex-direction: row-reverse;
	.massage-text {
		background-color: #e7f2ff;
	}
}
.massage-text {
	width: 70%;
}
.message:last-child {
	animation: message-enter 0.5s forwards;
}
@keyframes message-enter {
	from {
		transform: translateY(20px) scale(0.7);
		opacity: 0;
	}
	to {
		transform: translateY(0px) scale(1);
		opacity: 1;
	}
}
</style>
<style>
.message-highlight {
	background-color: rgba(255, 235, 205, 0.688);
}
</style>
