<template>
	<!-- loader -->
	<div
		class="d-flex justify-center align-center py-16"
		v-if="loading">
		<v-progress-circular
			indeterminate
			color="primary"></v-progress-circular>
	</div>

	<!-- main page -->
	<div
		v-else
		class="home">
		<!-- logout -->
		<div class="d-flex justify-end">
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						text
						v-bind="attrs"
						v-on="on">
						<v-icon left> mdi-account-circle </v-icon>
						اهلا بك {{ activeUser.FullName }}
					</v-btn>
				</template>
				<v-list dense>
					<template v-if="false">
						<v-list-item
							@click="login(user)"
							v-for="(user, i) in users"
							:class="{
								'primary--text':
									user.UserName === activeUser.UserName &&
									user.Password === activeUser.Password &&
									user.CenterId === activeUser.CenterId,
							}"
							:key="i">
							<v-list-item-title>
								{{ user.UserName }}
								-
								{{ getCenter(user.CenterId).Name }}
							</v-list-item-title>
						</v-list-item>
						<v-divider class="my-1"></v-divider>
						<v-list-item :to="{ name: 'login' }">
							<v-list-item-title>إضافة حساب</v-list-item-title>
						</v-list-item>
					</template>
					<v-list-item @click="openLogoutDialog = true">
						<v-list-item-title>تسجيل الخروج</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</div>

		<!-- tabs -->
		<v-tabs
			v-if="!$fromEdunix"
			v-model="tab"
			show-arrows
			color="myOrange"
			background-color="transparent"
			class="tabs"
			center-active>
			<v-tab
				class="font-weight-bold"
				href="#oneMessage">
				رسالة لطالب
			</v-tab>
			<v-tab
				class="font-weight-bold"
				href="#broadcast">
				رسالة جماعية
			</v-tab>
		</v-tabs>

		<v-divider class="mb-3"></v-divider>

		<v-tabs-items
			v-model="tab"
			class="py-2 white"
			touchless>
			<!-- one message -->
			<v-tab-item value="oneMessage">
				<div
					class="d-md-flex d-block"
					ref="container">
					<!-- filter form -->
					<div
						class="page-part"
						ref="filters">
						<!-- start chat button -->
						<v-row
							dense
							class="mb-3">
							<v-col
								cols="auto"
								class="flex-md-grow-1">
								<v-tooltip
									top
									:open-delay="1000">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-bind="attrs"
											v-on="on"
											block
											large
											color="primary"
											@click="dialog = true">
											<v-icon left> mdi-plus </v-icon>
											بدء محادثة
										</v-btn>
									</template>
									<span>alt+N</span>
								</v-tooltip>
							</v-col>
							<v-col cols="auto">
								<v-tooltip
									top
									:open-delay="1000">
									<template v-slot:activator="{ on, attrs }">
										<v-btn
											v-bind="attrs"
											v-on="on"
											large
											outlined
											color="primary"
											@click="refresh">
											<v-icon left> mdi-refresh </v-icon>
											تحديث
										</v-btn>
									</template>
									<span>alt+R</span>
								</v-tooltip>
							</v-col>
						</v-row>
						<v-divider></v-divider>

						<!-- the messages -->
						<h2 class="font-weight-bold text-h6 mb-2">الرسائل</h2>
						<v-sheet class="rounded-xl elevation-1 overflow-hidden">
							<v-data-table
								:headers="oldMessagesHeader"
								:items="
									loadingOldMessages
										? []
										: oldMessages?.Messages
								"
								@click:row="openMessage"
								:item-class="studentTableClass"
								:loading="loadingOldMessages"
								:loading-text="'انتظر قليلا...'"
								:no-data-text="
									unReadOnly
										? 'لاتوجد رسائل غير مقروءة'
										: 'لاتوجد رسائل'
								"
								:items-per-page="-1"
								hide-default-footer
								mobile-breakpoint="0"
								fixed-header
								height="calc(100vh - 310px)">
								<!-- switch for unread messages -->
								<template v-slot:top>
									<div class="d-flex justify-end px-5">
										<!-- <v-spacer></v-spacer> -->
										<v-switch
											@change="whatMessagesToShow"
											v-model="unReadOnly"
											label="الغير مقروءة فقط"></v-switch>
									</div>
								</template>

								<!-- button for unread messages -->
								<template v-slot:item.name="{ item }">
									{{ item.Student.Account.FullName }}
								</template>

								<!-- button for unread messages -->
								<template v-slot:item.IsOpened="{ item }">
									<!-- button to make it read -->
									<v-tooltip top>
										<template
											v-slot:activator="{ on, attrs }">
											<v-btn
												v-if="!item.IsOpened"
												:disabled="!!loadingMarkAsRead"
												:loading="
													loadingMarkAsRead ===
													item.Id
												"
												color="myOrangeBg"
												@click="markAsRead(item)"
												x-small
												fab
												elevation="0"
												v-bind="attrs"
												v-on="on">
												<v-icon
													small
													color="myOrange">
													mdi-eye-remove-outline
												</v-icon>
											</v-btn>
											<v-icon
												v-else
												class="pa-2"
												small
												color="myGreen"
												v-bind="attrs"
												v-on="on">
												mdi-eye-check-outline
											</v-icon>
										</template>
										<span v-if="!item.IsOpened">
											تحديد كمقروء
										</span>
										<span v-else>مقروءة</span>
									</v-tooltip>
								</template>

								<!-- slice the message -->
								<template v-slot:item.Content="{ item }">
									{{ item.Content.slice(0, 70) }}
									<span v-if="item.Content.length > 70">
										...
									</span>
								</template>

								<!-- format the date -->
								<template v-slot:item.SentAt="{ item }">
									{{
										dateFormaterJustDate(
											new Date(item.SentAt),
										)
									}}
								</template>
							</v-data-table>
						</v-sheet>
					</div>

					<v-divider
						vertical
						ref="divider"
						class="px-2 mx-2 d-none d-md-block"
						style="cursor: col-resize"></v-divider>

					<!-- chat -->
					<div
						class="page-part mt-4 mt-md-0"
						ref="chat">
						<template v-if="student">
							<chat
								@refresh="whatMessagesToShow"
								:loading="loadingChat"
								:student="student"
								:courseName="courseName"></chat>
						</template>
						<div
							v-else
							class="not-selected d-flex align-center justify-center text-h5 text--secondary py-16">
							اختر احد الطلاب
						</div>
					</div>
				</div>
			</v-tab-item>

			<!-- broadcast -->
			<v-tab-item value="broadcast">
				<v-row>
					<v-col
						cols="12"
						md="6">
						<!-- filter form -->
						<v-sheet class="white mb-4">
							<v-row
								class="ma-0 align-center"
								dense>
								<!-- class -->
								<v-col
									cols="12"
									sm="6"
									md="4">
									<v-select
										v-model="brodcastClass"
										:items="specialties"
										:disabled="
											!!brodcastToSchool ||
											loadingSendingBrodcast
										"
										item-text="SpecialtyName"
										item-value="Id"
										return-object
										@change="brodcastSubClass = null"
										label="الصف"
										outlined
										dense
										hide-details
										class="rounded-lg mb-1">
									</v-select>
								</v-col>

								<!-- subClass -->
								<v-col
									cols="12"
									sm="6"
									md="4">
									<v-select
										v-model="brodcastSubClass"
										:items="brodcastCourses"
										:disabled="
											!brodcastClass ||
											!!brodcastToSchool ||
											loadingSendingBrodcast
										"
										item-text="CourseName"
										item-value="Id"
										return-object
										label="الشعبة"
										outlined
										dense
										hide-details
										class="rounded-lg mb-1">
									</v-select>
								</v-col>

								<!-- select all classes -->
								<v-col
									cols="12"
									sm="6"
									md="4">
									<v-checkbox
										v-model="brodcastToSchool"
										:disabled="loadingSendingBrodcast"
										dense
										label="لكل المدرسة"></v-checkbox>
								</v-col>

								<!-- message -->
								<v-col cols="12">
									<v-textarea
										v-model="brodcastMessage"
										:disabled="loadingSendingBrodcast"
										label="الرسالة"
										outlined
										dense
										hide-details
										rows="4"
										auto-grow
										no-resize
										class="rounded-lg"></v-textarea>
								</v-col>

								<!-- send btn -->
								<v-col>
									<v-btn
										:disabled="
											((!brodcastClass ||
												!brodcastSubClass) &&
												!brodcastToSchool) ||
											loadingSendingBrodcast ||
											!brodcastMessage
										"
										:loading="loadingSendingBrodcast"
										x-large
										color="primary"
										@click="sendBrodcast">
										إرسال
										<v-icon
											right
											class="send-icon">
											mdi-send-variant
										</v-icon>
									</v-btn>
								</v-col>
							</v-row>
						</v-sheet>
					</v-col>

					<v-divider vertical></v-divider>
					<!-- all messages -->
					<v-col
						cols="12"
						md="6">
						<v-sheet
							v-if="brodcastMessages.length"
							class="transparent">
							<h2 class="ma-2">الرسائل الجماعية المرسلة</h2>
							<v-card
								class="rounded-xl elevation-1 overflow-hidden">
								<v-data-table
									:loading="loadingBrodcast"
									:headers="brodcastHeader"
									:items="
										loadingBrodcast ? [] : brodcastMessages
									"
									@click:row="showBrodcast"
									:items-per-page="-1"
									hide-default-footer
									mobile-breakpoint="0"
									fixed-header
									height="calc(100vh - 200px)">
									<!-- slice the message -->
									<template v-slot:item.Content="{ item }">
										{{ item.Content.slice(0, 70) }}
										<span v-if="item.Content.length > 70">
											...
										</span>
									</template>
									<template v-slot:item.CourseName="{ item }">
										{{
											item.AllSchool
												? 'لكل المدرسة'
												: item.SpecialtyName +
												  ' - ' +
												  item.CourseName
										}}
									</template>
									<template v-slot:item.SentAt="{ item }">
										{{
											dateFormaterJustDate(
												new Date(item.SentAt),
											)
										}}
									</template>
								</v-data-table>
							</v-card>
						</v-sheet>
					</v-col>
				</v-row>
			</v-tab-item>
		</v-tabs-items>

		<!-- start new message -->
		<v-dialog
			v-model="dialog"
			width="700">
			<v-card
				class="pa-4"
				rounded="lg">
				<h2 class="primary--text mb-5">بدء محادثة</h2>
				<!-- form -->
				<v-row dense>
					<!-- class -->

					<v-col
						cols="12"
						sm="5">
						<v-select
							v-model="oneMessageClass"
							:items="specialties"
							item-text="SpecialtyName"
							item-value="Id"
							return-object
							@change="oneMessageSubClass = null"
							label="الصف"
							outlined
							dense
							hide-details
							class="rounded-lg mb-1">
						</v-select>
					</v-col>

					<!-- subClass -->
					<v-col
						cols="12"
						sm="5">
						<v-select
							v-model="oneMessageSubClass"
							:disabled="!oneMessageClass"
							:items="oneMessageCourses"
							item-text="CourseName"
							item-value="Id"
							return-object
							label="الشعبة"
							outlined
							dense
							hide-details
							class="rounded-lg mb-1">
						</v-select>
					</v-col>

					<!-- btn -->
					<v-col
						cols="12"
						sm="2">
						<v-btn
							color="primary"
							@click="
								fetchStudents();
								isSearched = true;
							"
							:disabled="!oneMessageClass || !oneMessageSubClass">
							<v-icon left> mdi-magnify </v-icon>
							بحث
						</v-btn>
					</v-col>
				</v-row>

				<!-- student table -->
				<v-sheet class="mt-5">
					<!-- loader -->
					<div
						class="d-flex justify-center align-center py-16"
						v-if="loadingStudent">
						<v-progress-circular
							indeterminate
							color="primary"></v-progress-circular>
					</div>

					<!-- for no data -->
					<div
						class="py-10 text-center text-h5 text--secondary"
						v-else-if="!students.length && isSearched">
						لا يوجد طلاب في هذه الشعبة
					</div>

					<!-- student table -->
					<v-row
						v-else
						dense>
						<v-col
							cols="12"
							sm="6"
							md="4"
							v-for="studentIndex in students"
							:key="studentIndex.Id"
							class="">
							<v-card
								elevation="1"
								class="pa-1 text-center"
								@click="
									student = studentIndex;
									dialog = false;
									courseName = oneMessageSubClass.CourseName;
									fetchMessages();
								">
								{{ studentIndex.Account.FirstName }}
								{{ studentIndex.Account.LastName }}
							</v-card>
						</v-col>
					</v-row>
				</v-sheet>
			</v-card>
		</v-dialog>

		<!-- brodcast message -->
		<v-dialog
			v-model="showMessage"
			width="400">
			<v-card
				class="pa-6"
				rounded="lg">
				<template v-if="messageToShow">
					<div class="text-caption">
						تاريخ:
						{{
							dateFormaterJustDate(new Date(messageToShow.SentAt))
						}}
					</div>
					<div class="mb-3 text-caption">
						الى:
						<span v-if="messageToShow.AllSchool">
							لكل المدرسة
						</span>
						<span v-else>
							{{ messageToShow.SpecialtyName }} -
							{{ messageToShow.CourseName }}
						</span>
					</div>
					<div>
						{{ messageToShow.Content }}
					</div>
				</template>
			</v-card>
		</v-dialog>

		<!-- logout -->
		<v-dialog
			v-model="openLogoutDialog"
			width="400">
			<v-card
				class="pa-6"
				rounded="lg">
				<p class="text-center mb-8 text-h6">هل تريد تسجيل الخروج؟</p>
				<v-row>
					<v-col cols="6">
						<v-btn
							color="primary"
							block
							@click="openLogoutDialog = false">
							إلغاء
						</v-btn>
					</v-col>
					<v-col cols="6">
						<v-btn
							color="primary"
							text
							block
							@click="logout(activeUser)">
							تاكيد
						</v-btn>
					</v-col>
				</v-row>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import Chat from '@/components/Chat.vue';
import { mapGetters, mapState } from 'vuex';
import { dateFormaterJustDate } from '@/helpers/helpers';

export default {
	name: 'Home',

	components: { Chat },

	data() {
		return {
			//for all
			loading: true,
			loadingStudent: false,
			loadingOldMessages: false,
			loadingMarkAsRead: null,
			loadingBrodcast: true,
			loadingSendingBrodcast: false,
			loadingChat: false,

			tab: null,
			dialog: false,
			openLogoutDialog: false,
			isSearched: false,

			//for old messages
			unReadOnly: true,
			oldMessagesHeader: [
				{
					text: 'اسم الطالب',
					value: 'name',
				},
				{
					text: 'الصف - الشعبة',
					value: 'CourseName',
					filterable: false,
				},
				{
					text: 'التاريخ',
					value: 'SentAt',
					filterable: false,
				},
				{
					text: 'الرسالة',
					value: 'Content',
					sortable: false,
				},
				{
					text: 'مقروء',
					value: 'IsOpened',
					sortable: false,
					filterable: false,
				},
			],

			//for one message
			oneMessageClass: null,
			oneMessageSubClass: null,
			student: null,
			courseName: null,
			studentsHeader: [
				{
					text: 'اسم الطالب',
					value: 'userName',
				},
			],
			messageSearch: null,
			showSearch: false,

			//for brodcast
			brodcastClass: null,
			brodcastSubClass: null,
			brodcastToSchool: false,
			brodcastMessage: '',
			brodcastHeader: [
				{ text: 'الى', value: 'CourseName', width: '25%' },
				{ text: 'التاريخ', value: 'SentAt', width: '25%' },
				{
					text: 'الرسالة',
					value: 'Content',
				},
			],
			messageToShow: null,
			showMessage: false,
		};
	},

	computed: {
		unReadMessages() {
			return this.messages
				?.filter((e) => !e.IsOpened && !e.IsFromAdmin)
				.map((e) => e.Id);
		},
		oneMessageCourses() {
			return this.oneMessageClass ? this.oneMessageClass.Courses : [];
		},
		brodcastCourses() {
			return this.brodcastClass ? this.brodcastClass.Courses : [];
		},
		...mapState({
			users: (state) => state.login.users,
			activeUser: (state) => state.login.activeUser,
			specialties: (state) => state.message.specialties,
			students: (state) => state.message.students,
			oldMessages: (state) => state.message.oldMessages,
			brodcastMessages: (state) => state.message.brodcastMessages,
			messages: (state) => state.message.messages,
		}),

		...mapGetters({
			getCenter: 'login/getCenter',
		}),
	},

	methods: {
		// for all
		dateFormaterJustDate,
		logout(user) {
			this.$store.dispatch('login/logout', { user: user });
			this.$router.replace({ name: 'login' });
			this.$eventBus.$emit('show-snackbar', false, 'تم تسجيل الخروج');
		},
		login(user) {
			if (
				user.UserName === this.activeUser.UserName &&
				user.Password === this.activeUser.Password &&
				user.CenterId === this.activeUser.CenterId
			)
				return;
			this.$eventBus.$emit('loader', true);
			this.$store
				.dispatch('login/login', {
					userName: user.UserName,
					password: user.Password,
					centerId: user.CenterId,
				})
				.then(() => {
					this.$eventBus.$emit('loader', false);
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						'تم تبديل المستخدم',
					);
				});
		},
		fetch() {
			this.loading = true;
			Promise.all([
				this.$store.dispatch('message/fetchSpecialties'),
				this.$store.dispatch('message/fetchOldMessages', {
					unReadOnly: true,
					pageNumber: 1,
				}),
			])
				.then(() => {
					this.loading = false;
				})
				.catch(() => {})
				.finally(() => {
					this.$nextTick(() => {
						setTimeout(() => {
							this.initDragging();
						}, 1);
					});
				});
		},
		fetchStudents() {
			this.loadingStudent = true;
			this.$store
				.dispatch('message/fetchStudents', {
					courseId: this.oneMessageSubClass.Id,
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingStudent = false;
				});
		},
		initDragging() {
			if (!this.$refs.divider) return;
			let isDragging = false;
			this.$refs.divider.$el.addEventListener('mousedown', (e) => {
				isDragging = true;
				this.$refs.divider.$el.style.borderColor = '#0f4d96';
			});
			this.$refs.container.addEventListener('mousemove', (e) => {
				if (!isDragging) return;
				const containerRect =
					this.$refs.container.getBoundingClientRect();
				const x = e.clientX - containerRect.left;
				const containerWidth = containerRect.width;
				const draggableWidth = this.$refs.divider.$el.offsetWidth;
				const leftCol = this.$refs.chat;
				const rightCol = this.$refs.filters;
				const leftColWidth = x - draggableWidth / 2;
				const rightColWidth = containerWidth - x - draggableWidth / 2;
				leftCol.style.width = `${leftColWidth}px`;
				rightCol.style.width = `${rightColWidth}px`;
			});
			this.$refs.container.addEventListener('mouseup', (e) => {
				isDragging = false;
				this.$refs.divider.$el.style.borderColor =
					'rgba(0, 0, 0, 0.12)';
			});
		},

		// for brodcast
		sendBrodcast() {
			this.loadingSendingBrodcast = true;
			this.$store
				.dispatch('message/sendBrodcasetMessage', {
					Content: this.brodcastMessage,
					CourseId: this.brodcastSubClass?.Id,
					SpecialtyId: this.brodcastClass?.SpecialtyId,
					AllSchool: this.brodcastToSchool,
				})
				.then(() => {
					this.$eventBus.$emit(
						'show-snackbar',
						false,
						'تم إرسال الرسالة',
					);
					this.fetchBrodcastMessages();
					this.brodcastMessage = '';
				})
				.catch(() => {
					this.$eventBus.$emit('show-snackbar', true, 'فشل الإرسال');
				})
				.finally(() => {
					this.loadingSendingBrodcast = false;
				});
		},
		fetchBrodcastMessages() {
			this.loadingBrodcast = true;
			this.$store
				.dispatch('message/fetchAllBrodcast')
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingBrodcast = false;
				});
		},
		showBrodcast(message) {
			this.messageToShow = message;
			this.showMessage = true;
		},

		// for one message
		markAsRead(message) {
			this.loadingMarkAsRead = message.Id;
			this.$store
				.dispatch('message/markAsRead', { ids: [message.Id] })
				.then(() => {
					this.loadingOldMessages = true;
					this.$store
						.dispatch('message/fetchOldMessages', {
							unReadOnly: this.unReadOnly,
							pageNumber: 1,
						})
						.then(() => {
							this.$eventBus.$emit(
								'show-snackbar',
								false,
								'تم التحديد كمقروء',
							);
							this.refresh();
						})
						.catch(() => {})
						.finally(() => {
							this.loadingOldMessages = false;
						});
				})
				.catch(() => {})
				.finally(() => {
					this.loadingMarkAsRead = null;
				});
		},
		whatMessagesToShow() {
			this.loadingOldMessages = true;
			this.$store
				.dispatch('message/fetchOldMessages', {
					unReadOnly: this.unReadOnly,
					pageNumber: 1,
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingOldMessages = false;
				});
		},
		openMessage(message) {
			if (this.loadingMarkAsRead) return;
			this.student = {
				Id: message.StudentId,
				UserName: message.UserName,
				name: message.Student.Account.FullName,
			};
			this.courseName = message.CourseName;
			this.fetchMessages();
		},
		fetchMessages() {
			this.loadingChat = true;
			this.$store
				.dispatch('message/fetchMessages', {
					studentId: this.student.Id,
				})
				.then(() => {})
				.catch(() => {})
				.finally(() => {
					this.loadingChat = false;
				});
		},
		refresh() {
			this.whatMessagesToShow();
			if (this.student) this.fetchMessages();
		},
		studentTableClass(item) {
			if (item.Student.ID === this.student?.Id) return 'secondary--text';
		},
	},

	mounted() {
		this.$store.dispatch('clearAfterLogout');
		this.fetch();
		this.fetchBrodcastMessages();
		this.tab = this.$broadcast ? 'broadcast' : 'oneMessage';
	},

	created() {
		window.addEventListener('keydown', (e) => {
			if (e.altKey && e.code === 'KeyN') {
				e.preventDefault();
				this.dialog = true;
			}
			if (e.altKey && e.code === 'KeyR') {
				e.preventDefault();
				this.refresh();
			}
		});
	},
};
</script>

<style>
::-webkit-scrollbar {
	width: 7px;
}
::-webkit-scrollbar-thumb {
	background: #888;
}
::-webkit-scrollbar-track {
	background: #f1f1f1;
}
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
.home * {
	scrollbar-width: thin;
}
.student-cell {
	cursor: pointer;
	border: 1px solid #ccc;
}
.student-cell:hover {
	background-color: #eee;
}
.send-icon {
	transform: scale(-1);
}
.page-part {
	width: 50%;
}
@media (max-width: 960px) {
	.page-part {
		width: 100% !important;
	}
}
.v-data-table th,
.v-data-table td {
	padding: 0 9px !important;
}
</style>
